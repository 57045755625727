import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withPrefix } from 'gatsby';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO/SEO';
import Link from '@cof/graffiti-alley-spray-cans/atoms/Link';
import HeroBanner from '@cof/graffiti-alley-spray-cans/molecules/HeroBanner';
import Accordion from '@cof/graffiti-alley-spray-cans/molecules/Accordion';
import AccordionSection from '@cof/graffiti-alley-spray-cans/molecules/Accordion/AccordionSection';
import './contact-us-page.scss';
import { superScript } from '../../lib/utils';

const Concerns = injectIntl(({ intl, location }) => {
  const accordionSections = ['complaints', 'ombudsman', 'accessibility'];
  const accordionSectionTitles = {};
  accordionSections.forEach((section) => {
    accordionSectionTitles[section] = (
      <FormattedMessage
        id={`pages.contact-us.concerns.accordion.${section}.title`}
        values={{
          bolded: (
            <strong>
              <FormattedMessage id={`pages.contact-us.concerns.accordion.${section}.title-bolded`} />
            </strong>
          ),
        }}
      />
    );
  });

  return (
    <Layout intl={intl} location={location} pageName="contact-concerns-page">
      <section className="page-content-wrapper contact-us-concerns">
        <HeroBanner backgroundContentClass="page-hero-banner" mobileFriendly>
          <div className="content-wrapper">
            <p className="as-h1">
              <FormattedMessage id={'hero.contact-us.title'} />
            </p>
          </div>
        </HeroBanner>
        <div className="page-content">
          <h2>
            <FormattedMessage id="pages.contact-us.concerns.header" />
          </h2>
          <p>
            <FormattedMessage id="pages.contact-us.concerns.header-desc" values={{ rball: superScript('rball') }} />
          </p>
          <p className="strong-intro">
            <strong>
              <FormattedMessage id="pages.contact-us.concerns.intro" />
            </strong>
          </p>
          <Accordion>
            <AccordionSection title={accordionSectionTitles.complaints}>
              <div className="concerns-complaints">
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.p0" />
                </p>
                <h3 className="concerns-heading-smaller">
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.info.heading" />
                  </strong>
                </h3>
                <ul>
                  <li>
                    {' '}
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.info.p0" />
                  </li>
                  <li>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.info.p1" />
                  </li>
                </ul>
                <h3 className="concerns-heading-smaller">
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.heading" />
                  </strong>
                </h3>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p0" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p1" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p2" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p3" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p4" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p5" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p6" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p7" />
                  <br />
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p8" />
                  </strong>
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p9" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.contact.p10" />
                </p>
                <h3 className="concerns-heading-smaller">
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.process.heading" />
                  </strong>
                </h3>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.process.p0" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.process.p1" />
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.process.CRO" />
                  </strong>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.process.p2" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.process.p3" />
                </p>

                <h3 className="concerns-heading-smaller">
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.heading" />
                  </strong>
                </h3>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p0" />
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.OBSI" />
                  </strong>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.if" />
                </p>
                <ul>
                  <p>
                    <li>
                      {' '}
                      <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p1" />
                    </li>
                  </p>
                  <p>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p2" />
                  </p>
                  <p>
                    <li>
                      <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p3" />
                    </li>
                  </p>
                </ul>
                <p>
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p4" />
                  </strong>
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p5" />
                  <br />
                  <Link
                    to={intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.complaints.external.p6-link' })}
                  >
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p6" />
                  </Link>
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p7" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p8" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p9" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p10" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p11" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p12" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p13" />
                  <br />
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p14" />
                  </strong>
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p15" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p16" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p17" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p18" />
                  <br />
                  <Link
                    to={intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.complaints.external.p19-link' })}
                  >
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.external.p19" />
                  </Link>
                </p>
                <h3 className="concerns-heading-smaller">
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.heading" />
                  </strong>
                </h3>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p0" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p1" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p2" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p3" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p4" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p5" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p6" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p7" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p8" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p9" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p10" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p11" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p12" />
                  <br />
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p13" />
                  </strong>
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p14" />
                  <br />
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p15" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p16" />
                  <br />
                  <Link
                    to={intl.formatMessage({
                      id: 'pages.contact-us.concerns.accordion.complaints.financial-agency.p17-link',
                    })}
                  >
                    <FormattedMessage id="pages.contact-us.concerns.accordion.complaints.financial-agency.p17" />
                  </Link>
                </p>
              </div>
            </AccordionSection>
            <AccordionSection title={accordionSectionTitles.ombudsman}>
              <div className="concerns-ombudsman">
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p0" />
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p1" />
                </p>
                <div className="contact-us-concerns-complaints-report-2023">
                  <p>
                    <strong className="strong-intro">
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p11" />
                    </strong>
                  </p>
                  <a
                    href={withPrefix(
                      intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.ombudsman.p12-link' }),
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="ombudsman-pdf-link">
                      <strong className="strong-pdf-link">
                        <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p12" />
                      </strong>
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p12-span" />
                    </p>
                  </a>
                </div>
                <div className="contact-us-concerns-complaints-report-2022">
                  <p>
                    <strong className="strong-intro">
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p9" />
                    </strong>
                  </p>
                  <a
                    href={withPrefix(
                      intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.ombudsman.p10-link' }),
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="ombudsman-pdf-link">
                      <strong className="strong-pdf-link">
                        <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p10" />
                      </strong>
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p10-span" />
                    </p>
                  </a>
                </div>
                <div className="contact-us-concerns-ombudsman-report-2021">
                  <p>
                    <strong className="strong-intro">
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p2" />
                    </strong>
                  </p>
                  <a
                    href={withPrefix(
                      intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.ombudsman.p3-link' }),
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="ombudsman-pdf-link">
                      <strong className="strong-pdf-link">
                        <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p3" />
                      </strong>
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p3-span" />
                    </p>
                  </a>
                </div>
                <div className="contact-us-concerns-ombudsman-report-2020">
                  <p>
                    <strong className="strong-intro">
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p5" />
                    </strong>
                  </p>
                  <a
                    href={withPrefix(
                      intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.ombudsman.p6-link' }),
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="ombudsman-pdf-link">
                      <strong className="strong-pdf-link">
                        <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p6" />
                      </strong>
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p6-span" />
                    </p>
                  </a>
                </div>
                <div className="contact-us-concerns-ombudsman-report-2019">
                  <p>
                    <strong className="strong-intro">
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p7" />
                    </strong>
                  </p>
                  <a
                    href={withPrefix(
                      intl.formatMessage({ id: 'pages.contact-us.concerns.accordion.ombudsman.p8-link' }),
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="ombudsman-pdf-link">
                      <strong className="strong-pdf-link">
                        <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p8" />
                      </strong>
                      <FormattedMessage id="pages.contact-us.concerns.accordion.ombudsman.p8-span" />
                    </p>
                  </a>
                </div>
              </div>
            </AccordionSection>
            <AccordionSection title={accordionSectionTitles.accessibility}>
              <div className="concerns-accessibility">
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p0" />
                </p>
                <p>
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p1" />
                  </strong>
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p2" />
                </p>
                <p>
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p3" />
                  </strong>
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p4" />
                </p>
                <p>
                  <strong>
                    <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p5" />
                  </strong>
                </p>
                <p>
                  <FormattedMessage id="pages.contact-us.concerns.accordion.accessibility.p6" />
                </p>
              </div>
            </AccordionSection>
          </Accordion>
        </div>
      </section>
    </Layout>
  );
});

export default Concerns;

/* eslint-disable react/prop-types */
export const Head = ({ pageContext }) => (
  <SEO
    titleKey="pages.contact-us.concerns.page-title"
    descriptionKey="pages.contact-us.concerns.page-desc"
    intl={pageContext.intl}
  />
);
/* eslint-enable react/prop-types */
